import { useState, useEffect } from "react";

import { fallbackLanguage } from "../constants/fallbackLanguage";

const currentLocale = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

export const LocalizedImage = ({ src, className, svg }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [svgSrc, setSvgSrc] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const imageModule = await import(`../assets/images/${currentLocale}/${src}`);

        setImageSrc(imageModule.default);
        setSvgSrc(imageModule.default);
      } catch (error) {
        console.error("Failed to load image:", error);
      }
    })();
  }, [src]);

  if (!imageSrc) {
    return <></>;
  }

  if (!!svg) {
    return svgSrc;
  }

  return <img src={imageSrc} alt={src} className={className} />;
};
