import { Fragment, useState, useMemo, useRef, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import { paths } from "../constants/paths";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useTranslation } from "../hooks/useTranslation";

import { ReactComponent as Bolt } from "../assets/bolt.svg";
import { ReactComponent as UserGroup } from "../assets/user-group.svg";
import { ReactComponent as DocumentText } from "../assets/document-text.svg";

import { LocalizedImage } from "./LocalizedImage";
import { ContainedButtonWithIcon } from "./buttons/ContainedButtonWithIcon";

export const Opportunities = () => {
  const { t } = useTranslation();
  const [currentOpportunity, setCurrentOpportunity] = useState(0);
  const [gradient, setGradient] = useState({ left: false, right: false });
  const ref = useRef(null);

  const opportunities = useMemo(
    () => [
      {
        title: t.vacanciesTitle,
        text: t.createJobPage,
        src: "opportunity-1.jpg",
        icon: <DocumentText className="icon-20 text-primary" />,
        buttonText: t.vacancies,
        to: paths.vacancies,
      },
      {
        title: t.atsDatabase,
        text: t.candidateManagement,
        src: "opportunity-2.jpg",
        icon: <UserGroup className="icon-20 text-primary" />,
        buttonText: t.atsDatabase,
        to: paths.atsDatabase,
      },
      {
        title: t.hrPlatform,
        text: t.thePlatformAllows,
        src: "opportunity-3.jpg",
        icon: <Bolt className="icon-20 text-primary" />,
        buttonText: t.hrPlatform,
        to: paths.hrPlatform,
      },
    ],
    [t]
  );

  const updateImage = (number) => {
    setCurrentOpportunity(number);
  };

  const isMobile = useMediaQuery("(max-width: 540px)");

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { scrollLeft, scrollWidth, clientWidth } = ref.current;

        setGradient({ left: scrollLeft > 0, right: scrollLeft < scrollWidth - clientWidth - 1 });
      }
    };

    const container = ref.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      <h2 className="text-center text-secondary">{t.opportunities}</h2>
      <div className="opportunities-button-container">
        <div
          ref={ref}
          className={`opportunities-buttons ${
            gradient.right ? "after-gradient-right-to-left" : ""
          } ${gradient.left ? "before-gradient-left-to-right" : ""}`}
        >
          {opportunities.map((opp, index) => (
            <div
              key={index}
              onClick={(e) => {
                updateImage(index);

                const isLeft = ![16, 30, 90].includes(e.target.offsetLeft);
                const isRight = e.target.offsetLeft < ref.current.offsetWidth;

                setGradient({ left: isLeft, right: isRight });

                if (ref.current) {
                  const left = e.target.offsetLeft - ref.current.offsetWidth / 2 + e.target.offsetWidth / 2;

                  ref.current.scrollTo({ left, behavior: "smooth" });
                }
              }}
              className={`opportunities-button ${
                currentOpportunity === index ? "active" : ""
              }`}
            >
              <div className="icon-container">{opp.icon}</div>
              <span>{opp.buttonText}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="opportunities-content">
        <div className="opportunities-description">
          {opportunities.map((opp, index) => (
            <Fragment key={index}>
              <h4
                className={`text-secondary opp-text ${
                  currentOpportunity !== index ? "hidden" : ""
                }`}
                data-opportunity-text={index}
              >
                {opp.title}
              </h4>
              <p
                className={`opportunities-text body-small opp-text ${
                  currentOpportunity !== index ? "hidden" : ""
                }`}
                data-opportunity-text={index}
              >
                {opp.text}
              </p>
            </Fragment>
          ))}

          <Link to={opportunities[currentOpportunity].to}>
            <ContainedButtonWithIcon className="mt-40" title={t.learnMore} />
          </Link>
        </div>
        <div className="opportunities-image-container">
          {opportunities.map((opp, index) => (
            <LocalizedImage
              key={opp.src}
              src={opp.src}
              className={`opportunities-image ${
                currentOpportunity !== index ? "opacity-0" : ""
              }`}
            />
          ))}
        </div>
      </div>
      {isMobile && (
        <>
          {opportunities.map((item, index) => (
            <div key={item.to}>
              <p
                className={`body-large text-secondary weight-500 ${
                  index === 0 ? "mt-28" : "mt-60"
                }`}
              >
                {item.title}
              </p>
              <LocalizedImage
                key={item.src}
                src={item.src}
                className="opportunity-image"
              />
              <p className="body-small text-tertiary mt-12">{item.text}</p>
              <Link to={item.to}>
                <ContainedButtonWithIcon
                  className="mt-28"
                  title={t.learnMore}
                />
              </Link>
            </div>
          ))}
        </>
      )}
    </>
  );
};
