import { Children, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as Sparkles } from "../assets/sparkles.svg";

import { getClonedItems } from "../utils/getClonedItems";
import { useTicker } from "../hooks/useTicker";
import { useTranslation } from "../hooks/useTranslation";

export const Ticker = ({
  children,
  duration = 50,
  threshold = 0.1,
  direction = "left",
  title,
}) => {
  const tickerContainerRef = useRef(null);
  const tickerBoxRef = useRef(null);
  const [showItems, setShowItems] = useState(Children.toArray(children));
  const [ticker, setTicker] = useState({
    translate: 0,
    duration,
    animationName: `marqueeScroll-${title}`,
    isPlaying: true,
    isLoading: false,
  });

  const isVisible = useTicker({
    repeat: true,
    margin: "10px",
    threshold: [threshold],
    ref: tickerContainerRef,
  });

  useEffect(() => {
    const containerWidth = Math.floor(tickerContainerRef.current.offsetWidth);
    const itemsWidth = Math.floor(tickerBoxRef.current.scrollWidth) / 2;
    const cloneTimes = Math.max(
      2,
      Math.ceil((containerWidth * 2) / itemsWidth)
    );
    const translateFromValue = itemsWidth * Math.floor(cloneTimes / 2);
    const durationValue =
      duration * parseFloat((translateFromValue / containerWidth).toFixed(2));

    setShowItems(getClonedItems(Children.toArray(children), cloneTimes));
    setTicker((prevState) => ({
      ...prevState,
      translate: translateFromValue,
      duration: durationValue,
    }));
  }, [
    children,
    duration,
    tickerBoxRef?.current?.scrollWidth,
    tickerContainerRef?.current?.offsetWidth,
  ]);

  useEffect(() => {
    setTicker((prevState) => ({ ...prevState, isPlaying: isVisible }));
  }, [isVisible]);

  const tickerContainerStyle = {
    width: "100vw",
    overflow: "hidden",
    position: "relative",
  };

  const tickerBoxStyle = {
    whiteSpace: "nowrap",
    display: "flex",
    width: "max-content",
    transform: `translate3d(-${ticker.translate}px, 0, 0)`,
    animationName: ticker.animationName,
    animationDuration: `${ticker.duration}s`,
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationPlayState: ticker.isPlaying ? "running" : "paused",
    animationDirection: direction === "right" ? "reverse" : "normal",
  };

  const tickerItemStyle = {
    position: "relative",
    display: "inline-block",
    marginRight: "20px",
    minHeight: "100%",
    flexGrow: 1,
  };

  return (
    <div ref={tickerContainerRef} style={tickerContainerStyle} className="ticker-container">
      <style>
        {`
            @keyframes ${ticker.animationName} {
              to { transform: translate3d(0, 0, 0); }
            }
            @media (max-width: 600px) {
              .${ticker.animationName}-box {
                animation-duration: ${Number(ticker?.duration) / 2}s !important;
              }
              .ticker-item {
                margin-right: 20px !important;
              }
            }
          `}
      </style>
      <div ref={tickerBoxRef} style={tickerBoxStyle} className={`ticker-box ${ticker.animationName}-box`}>
        {showItems.map((item, index) => (
          <div
            key={`${title}-${index}`}
            style={tickerItemStyle}
            className="ticker-item"
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export const HomeTicker = () => {
  const { t } = useTranslation();

  const phrases = useMemo(
    () => [
      t.noExcessiveTools,
      t.dragAndDropInterface,
      t.integrationWithPopularSites,
      t.onlyKeyFunctions,
      t.intuitiveInterface,
      t.lessTimeToProcessTasks,
      t.quickAccessToTools,
      t.easySetting,
      t.secureDataStorage,
      t.taskAutomation,
      t.possibilityOfCustomization,
    ],
    [t]
  );

  return (
    <Ticker title="main" direction="right">
      {phrases.map((item, index) => (
        <span key={item || `ticker-${index}`} className="rounded-item">
          <Sparkles className="icon-20 text-primary" />
          {item}
        </span>
      ))}
    </Ticker>
  );
};
