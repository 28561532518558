import { forwardRef, useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import en from "../assets/flags/en.svg";
import de from "../assets/flags/de.svg";
import uk from "../assets/flags/uk.svg";

import { paths } from "../constants/paths";
import { useTranslation } from "../hooks/useTranslation";
import { useOutsideClick } from "../hooks/useOutsideClick";

const flag = { en, uk, de };
const flagIcon = (language) => (
  <img src={flag[language]} alt="flag icon" className="icon-16" />
);
const languageList = [
  { title: "de", to: paths.de },
  { title: "en", to: paths.eu },
  { title: "uk", to: paths.uk },
];

export const LanguageButton = forwardRef(({ className }, ref) => {
  const { currentLocale } = useTranslation();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const selectLanguage = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const { ref: outsideRef } = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
      document.body.style.overflow = "";
    }
  });

  return (
    <div className={`language-dropdown ${className}`} ref={ref}>
      <button
        id="language-dropdown-btn"
        aria-haspopup="true"
        aria-expanded={isOpen}
        className={`button-text ${isOpen ? "pointer-events-none" : ""}`}
        onClick={toggleDropdown}
      >
        <span
          id="selected-language"
          className="body-medium weight-500"
          data-lang={currentLocale}
        >
          <img
            src={flag[currentLocale]}
            alt={`${currentLocale} flag`}
            className={`icon-20 ${isOpen ? "language-border-8" : ""}`}
          />{" "}
          {currentLocale}
        </span>
      </button>

      <ul
        ref={outsideRef}
        id="language-dropdown-menu"
        role="menu"
        aria-labelledby="language-dropdown-btn"
        className={`dropdown-list shadow-7 ${isOpen ? "show" : ""}`}
      >
        {languageList.map((lang) => (
          <Link to={`${lang.to}${pathname}`} key={lang.to} role="menuitem">
            <li role="menuitem">
              <button
                data-lang={lang.title}
                className={`dropdown-button ${
                  currentLocale === lang.title ? "active" : ""
                }`}
                onClick={selectLanguage}
              >
                {flagIcon(lang.title)}{" "}
                <span className="body-small text-tertiary weight-500">
                  {lang.title}
                </span>
              </button>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
});
