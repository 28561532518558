import { useState, useEffect } from "react";

import { fallbackLanguage } from "../constants/fallbackLanguage";

const DEFAULT_LOCALE = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

export const useTranslation = () => {
  const [t, setTranslation] = useState({});
  const [currentLocale, setCurrentLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    (async () => {
      try {
        const module = await import(
          `../constants/locales/${currentLocale}/common.js`
        );

        setTranslation(module.default || module);
      } catch (error) {
        if (currentLocale !== DEFAULT_LOCALE) {
          setCurrentLocale(DEFAULT_LOCALE);
        }
      }
    })();
  }, [currentLocale]);

  return { t, currentLocale, setCurrentLocale };
};
