import { forwardRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as CheckIcon } from "../assets/check.svg";
import { ReactComponent as XMarkIcon } from "../assets/x-mark.svg";
import { ReactComponent as ChevronDown } from "../assets/chevron-down.svg";
import { ReactComponent as PricingGradient } from "../assets/pricing-gradient.svg";
import { ReactComponent as PricingGradient2 } from "../assets/pricing-gradient-2.svg";

import { FAQ } from "./FAQ";
import { Prices } from "./Prices";
import { Reviews } from "./Reviews";
import { ModalJoinForFree } from "./ModalJoinForFree";
import { ContainedButtonWithIcon } from "./buttons/ContainedButtonWithIcon";

import { tryForFree } from "../utils/getPath";
import { useCollapse } from "../hooks/useCollapse";
import { useTranslation } from "../hooks/useTranslation";
import { useOutsideClick } from "../hooks/useOutsideClick";

const setNumberOfEmployee = (string, number) => {
  if (!string) {
    return "";
  }

  return string.replace("number", number);
};

const AccordionItem = forwardRef(
  ({ title, description, isActive, maxHeight }, ref) => {
    return (
      <div className={`prices-table-cell accordion`}>
        <span
          className={`prices-table-cell-accordion-item weight-500 ${isActive}`}
        >
          {title}
          <ChevronDown className="icon-20 chvrn" />
        </span>
        <p
          ref={ref}
          style={{ maxHeight }}
          className={`collapsed-content table-mt ${isActive}`}
        >
          {description}
        </p>
      </div>
    );
  }
);

const PriceTableRow = ({ rowData }) => {
  const { collapsed, ref, toggle } = useCollapse();
  const isActive = collapsed.value ? "active" : "";

  return (
    <div className={`prices-table-row ${isActive}`} onClick={toggle}>
      <AccordionItem
        ref={ref}
        isActive={isActive}
        title={rowData.title}
        maxHeight={collapsed.height}
        description={rowData.description}
      />
      {rowData.start && <PriceTableCell content={rowData.start} />}
      {rowData.startImage && <PriceTableImage image={rowData.startImage} />}
      {rowData.scaleImage && <PriceTableImage image={rowData.scaleImage} />}
      {rowData.scale && <PriceTableCell content={rowData.scale} />}
      {rowData.enterprise && <PriceTableCell content={rowData.enterprise} />}
      {rowData.enterpriseImage && (
        <PriceTableImage image={rowData.enterpriseImage} />
      )}
    </div>
  );
};

const PriceTableCell = ({ content }) => (
  <div className="prices-table-cell justify-center">{content}</div>
);

const PriceTableImage = ({ image }) => (
  <div className="prices-table-cell justify-center">
    <div className={`${image.cls || "icon-20"} text-primary`}>{image}</div>
  </div>
);

const PriceTable = ({ config, title }) => {
  return (
    <div className="prices-table">
      <p className="mt-40 body-large weight-600 text-secondary">{title}</p>
      <div className="table-divider"></div>
      {config.map((rowData, index) => (
        <PriceTableRow
          key={rowData.title || `table-${index}`}
          rowData={rowData}
        />
      ))}
    </div>
  );
};

const initialFormState = {
  formType: { value: "charity", error: "" },
  nameOfTheFund: { value: "", error: "" },
  emailCharity: { value: "", error: "" },
  identificationCode: { value: "", error: "" },
  countryAndCityOfRegistration: { value: "", error: "" },
  descriptionOfActivities: { value: "", error: "" },
  companyName: { value: "", error: "" },
  emailStartup: { value: "", error: "" },
  yearOfEstablishment: { value: "", error: "" },
  startupDescription: { value: "", error: "" },
  privacy: { value: false, error: "" },
};

export const PagePrices = () => {
  const { t } = useTranslation();

  const hrPlatformConfig = useMemo(
    () => [
      {
        title: t.hrTitle1,
        description: t.hrDescription1,
        start: setNumberOfEmployee(t.tillNumberEmployees, 10),
        scale: setNumberOfEmployee(t.tillNumberEmployees, 50),
        enterprise: t.noResctrictions,
      },
      {
        title: t.hrTitle2,
        description: t.hrDescription2,
        start: setNumberOfEmployee(t.tillNumberEmployees, 2),
        scale: setNumberOfEmployee(t.tillNumberEmployees, 10),
        enterprise: t.noResctrictions,
      },
      {
        title: t.hrTitle3,
        description: t.hrDescription3,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hrTitle4,
        description: t.hrDescription4,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hrTitle5,
        description: t.hrDescription5,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
    ],
    [t]
  );

  const hiringEmployeeConfig = useMemo(
    () => [
      {
        title: t.hiringTitle1,
        description: t.hiringDescription1,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hiringTitle2,
        description: t.hiringDescription2,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hiringTitle3,
        description: t.hiringDescription3,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hiringTitle4,
        description: t.hiringDescription4,
        startImage: <XMarkIcon className="text-red" />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.hiringTitle5,
        description: t.hiringDescription5,
        startImage: <XMarkIcon className="text-red" />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
    ],
    [t]
  );

  const dataProtectionConfig = useMemo(
    () => [
      {
        title: t.dataProtectionTitle1,
        description: t.dataProtectionDescription1,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.dataProtectionTitle2,
        description: t.dataProtectionDescription2,
        startImage: <XMarkIcon className="text-red" />,
        scaleImage: <XMarkIcon className="text-red" />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.dataProtectionTitle3,
        description: t.dataProtectionDescription3,
        startImage: <XMarkIcon className="text-red" />,
        scaleImage: <XMarkIcon className="text-red" />,
        enterpriseImage: <CheckIcon />,
      },
    ],
    [t]
  );

  const comingSoonConfig = useMemo(
    () => [
      {
        title: t.comingSoonTitle1,
        description: t.comingSoonDescription1,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle2,
        description: t.comingSoonDescription2,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle3,
        description: t.comingSoonDescription3,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle4,
        description: t.comingSoonDescription4,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle5,
        description: t.comingSoonDescription5,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle6,
        description: t.comingSoonDescription6,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
      {
        title: t.comingSoonTitle7,
        description: t.comingSoonDescription7,
        startImage: <CheckIcon />,
        scaleImage: <CheckIcon />,
        enterpriseImage: <CheckIcon />,
      },
    ],
    [t]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);

    if (isModalOpen) {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }

    setFormState(initialFormState);
  };

  const { ref } = useOutsideClick(() => {
    if (isModalOpen) {
      toggleModal();
    }
  });

  return (
    <div>
      <div className="section section-prices-page">
        <Prices variant="prices" toggleModal={toggleModal} />
      </div>

      <ModalJoinForFree
        ref={ref}
        open={isModalOpen}
        formState={formState}
        setFormState={setFormState}
        toggleModal={toggleModal}
      />

      <div className="relative">
        <PricingGradient className="section-prices-page-gradient" />

        <div className="section section-prices-page-content">
          <PricingGradient2 className="section-prices-page-gradient-2" />
          <h2 className="text-center text-secondary">{t.compareTariffPlans}</h2>
          <p className="mt-16 text-center body-medium text-tertiary">
            {t.overviewPlans}
          </p>

          <div className="prices-table">
            <div className="prices-table-row head">
              <div className="prices-options-text">{t.options}</div>
              <div className="prices-table-element">Start</div>
              <div className="prices-table-element">Scale</div>
              <div className="prices-table-element">Pro</div>
            </div>

            <div className="prices-table-1">
              <PriceTable config={hrPlatformConfig} title={t.hrPlatform} />
            </div>
            <div className="prices-table-2">
              <PriceTable
                config={hiringEmployeeConfig}
                title={t.hiringEmployees}
              />
            </div>
            <div className="prices-table-3">
              <PriceTable
                config={dataProtectionConfig}
                title={t.dataProtection}
              />
            </div>
            <div className="prices-table-4">
              <PriceTable config={comingSoonConfig} title={t.comingSoon} />
            </div>
          </div>

          <div className="mt-40 flex justify-center">
            <Link to={tryForFree("Company")} className="z-1">
              <ContainedButtonWithIcon title={t.tryForFree} />
            </Link>
          </div>
        </div>
      </div>

      <FAQ />

      <Reviews />
    </div>
  );
};
