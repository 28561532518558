import { Text } from "./form/Text";
import { TextArea } from "./form/Textarea";
import { useTranslation } from "../hooks/useTranslation";

export const FormCharity = ({ formData, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-28">
      <Text
        required
        type="text"
        name="nameOfTheFund"
        label={t.fundNameLabel}
        placeholder={t.fundNamePlaceholder}
        onChange={onChange}
        value={formData.nameOfTheFund.value}
        error={formData.nameOfTheFund.error}
      />
      <Text
        required
        type="email"
        name="emailCharity"
        label={t.emailLabel}
        placeholder={t.emailPlaceholder}
        containerClassName="mt-12"
        onChange={onChange}
        value={formData.emailCharity.value}
        error={formData.emailCharity.error}
      />
      <Text
        required
        type="text"
        name="identificationCode"
        label={t.fundIdLabel}
        placeholder={t.fundIdPlaceholder}
        containerClassName="mt-12"
        onChange={onChange}
        value={formData.identificationCode.value}
        error={formData.identificationCode.error}
      />
      <Text
        required
        type="text"
        name="countryAndCityOfRegistration"
        label={t.countryAndCityOfRegistrationLabel}
        placeholder={t.countryAndCityOfRegistrationPlaceholder}
        containerClassName="mt-12"
        onChange={onChange}
        value={formData.countryAndCityOfRegistration.value}
        error={formData.countryAndCityOfRegistration.error}
      />
      <TextArea
        required
        type="textarea"
        name="descriptionOfActivities"
        label={t.descriptionLabel}
        placeholder={t.descriptionPlaceholder}
        containerClassName="mt-12"
        onChange={onChange}
        value={formData.descriptionOfActivities.value}
        error={formData.descriptionOfActivities.error}
      />
    </div>
  );
};
