import { useCallback, useMemo, useState } from "react";

import { getRandomEmoji } from "../../utils/getRandomEmoji";
import { useTranslation } from "../../hooks/useTranslation";
import { useValidateField } from "../../hooks/useValidateField";

import "./products.css";

import { Text } from "../form/Text";
import { TextArea } from "../form/Textarea";
import { ProductHead } from "./ProductHead";
import { LocalizedImage } from "../LocalizedImage";
import { ProductSectionList } from "./ProductSectionList";

const initialFormState = {
  name: { value: "", error: "" },
  email: { value: "", error: "" },
  request: { value: "", error: "" },
};

export const PageProductRecruitingAsService = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState(initialFormState);
  const { validateField } = useValidateField();

  const list = useMemo(
    () => [
      {
        stepTitle: `${t.step} 1`,
        title: t.recruitingStep1Title,
        description: t.recruitingStep1Description,
        image: "service-step-1.png",
      },
      {
        stepTitle: `${t.step} 2`,
        title: t.recruitingStep2Title,
        description: t.recruitingStep2Description,
        image: "service-step-2.png",
      },
      {
        stepTitle: `${t.step} 3`,
        title: t.recruitingStep3Title,
        description: t.recruitingStep3Description,
        image: "service-step-3.png",
      },
      {
        stepTitle: `${t.step} 4`,
        title: t.recruitingStep4Title,
        description: t.recruitingStep4Description,
        image: "service-step-4.png",
      },
    ],
    [t]
  );

  const tags = useMemo(
    () => [t.product1tag1, t.product1tag2, t.product1tag3],
    [t]
  );

  const handleChange = useCallback((field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: {
        value: value,
        error: validateField(field, value),
      },
    }));
  }, [t]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const newFormState = { ...formState };

    let isValid = true;

    Object.keys(newFormState).forEach((key) => {
      const error = validateField(key, newFormState[key].value);

      newFormState[key].error = error;

      if (error) {
        isValid = false;
      }
    });

    if (isValid) {
      try {
        const icon_emoji = getRandomEmoji();
        const formData = Object.keys(newFormState).reduce((acc, key) => {
          acc[key] = newFormState[key].value;

          return acc;
        }, {});
        const text = `Name: ${formData.name}\nEmail: ${formData.email}\nRequest: ${formData.request}`
        const username = 'Form: Recruiting as service';
        const data = {
          text,
          username,
          icon_emoji,
          channel: "#site_inbox",
        };

        await fetch(
          "https://hooks.slack.com/services/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",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "no-cors",
            body: JSON.stringify(data),
          }
        );

        setFormState(initialFormState);
      } catch (error) {
        setFormState(newFormState);
      }
    } else {
      setFormState(newFormState);
    }
  }, [formState, t]);

  return (
    <>
      <ProductHead
        animation
        tags={tags}
        title={t.productRecruitingAsServiceTitle}
        subtitle={t.productRecruitingAsServiceSubtitle}
      />

      <ProductSectionList list={list} />

      <div className="section">
        <div className="service-bottom">
          <div className="mt-24">
            <h2 className="text-secondary">{t.gotQuestions}</h2>
            <p className="mt-12 body-medium text-tertiary max-w-488">
              {t.fillForm}
            </p>
            <div className="mt-36 flex items-center gap-12">
              <LocalizedImage
                src="service-avatar-1.jpg"
                className="icon-50 rounded-full"
              />

              <div>
                <p className="body-medium text-secondary weight-500">
                  Name Surname
                </p>
                <p className="body-small text-primary">{t.m}</p>
              </div>
            </div>
            <LocalizedImage
              src="contact-us-help.png"
              className="contact-form-image mt-60"
            />
          </div>
          <div className="contact-form-card max-w-488 shadow-8 relative">
            <h4 className="text-secondary">{t.contactWithUs}</h4>

            <div className="blur-ball blur-size-460 blur-bg-2 blur-filter-90 left-bottom"></div>

            <form noValidate onSubmit={handleSubmit} className="mt-28">
              <Text
                required
                type="text"
                name="name"
                label={t.nameLabel}
                placeholder={t.namePlaceholder}
                onChange={handleChange}
                value={formState.name.value}
                error={formState.name.error}
              />
              <Text
                required
                type="email"
                name="email"
                label={t.emailLabel}
                placeholder={t.emailPlaceholder}
                containerClassName="mt-12"
                onChange={handleChange}
                value={formState.email.value}
                error={formState.email.error}
              />
              <TextArea
                required
                type="textarea"
                name="request"
                label={t.yourRequestLabel}
                placeholder={t.yourRequestPlaceholder}
                containerClassName="mt-12"
                onChange={handleChange}
                value={formState.request.value}
                error={formState.request.error}
              />
              <button
                type="submit"
                className="button contained-primary mt-28 w-full"
              >
                {t.send}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
