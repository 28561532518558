import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

import geometry from "../assets/geometry.svg";
import folder from "../assets/folder.svg";
import { ReactComponent as Sparkles } from "../assets/sparkles.svg";

import { tryForFree } from "../utils/getPath";
import { useTranslation } from "../hooks/useTranslation";
import { useIntersecting } from "../hooks/useIntersecting";
import { useOutsideClick } from "../hooks/useOutsideClick";

import { Tasks } from "./Tasks";
import { Bother } from "./Bother";
import { Prices } from "./Prices";
import { Targets } from "./Targets";
import { Reviews } from "./Reviews";
import { HomeTicker } from "./Ticker";
import { Opportunities } from "./Opportunities";
import { LocalizedImage } from "./LocalizedImage";
import { IntegrationCards } from "./IntegrationCards";
import { ModalJoinForFree } from "./ModalJoinForFree";
import { ContainedButtonWithIcon } from "./buttons/ContainedButtonWithIcon";
import { useMediaQuery } from "../hooks/useMediaQuery";

const initialFormState = {
  formType: { value: "charity", error: "" },
  nameOfTheFund: { value: "", error: "" },
  emailCharity: { value: "", error: "" },
  identificationCode: { value: "", error: "" },
  countryAndCityOfRegistration: { value: "", error: "" },
  descriptionOfActivities: { value: "", error: "" },
  companyName: { value: "", error: "" },
  emailStartup: { value: "", error: "" },
  yearOfEstablishment: { value: "", error: "" },
  startupDescription: { value: "", error: "" },
  privacy: { value: false, error: "" },
};

const sections = [
  { id: "hero", threshold: 0.1 },
  { id: "innovate", threshold: 0.1 },
  { id: "bother", threshold: 0.5 },
  { id: "tasks", threshold: 0.7 },
  { id: "opportunities", threshold: 0.5 },
  { id: "mobile-ticker", threshold: 0.5 },
  { id: "prices-page", threshold: 0.3 },
  { id: "integrations", threshold: 0.6 },
  { id: "target", threshold: 0.4 },
  { id: "reviews", threshold: 0.3 },
];

export const Home = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const sectionRefs = useRef([]);

  useEffect(() => {
    setTimeout(() => setIsPaused(false), 2000);
  }, []);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "" : "hidden";
    setFormState(initialFormState);
  };

  const innovateList = useMemo(
    () => [
      { title: t.managers, description: t.focusOnGrowingTheirBusiness },
      { title: t.recruiters, description: t.focusOnFindingTheBestCandidates },
      {
        title: t.hrManagers,
        description: t.getToolsForOrganizingEmployeeInformation,
      },
    ],
    [t]
  );

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, sections.length);
  }, []);

  const scrollToSection = (index) => {
    const item = sectionRefs.current[index];

    if (item) {
      const viewportHeight = window.innerHeight;
      const itemRect = item.getBoundingClientRect();
      const scrollTo =
        window.scrollY +
        itemRect.top -
        viewportHeight / 2 +
        itemRect.height / 2;

      window.scrollTo({ top: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!isPaused) {
      scrollToSection(currentSectionIndex);
    }
  }, [currentSectionIndex]);

  const { ref } = useOutsideClick(() => {
    if (isModalOpen) {
      toggleModal();
    }
  });

  const isLargeScreen = useMediaQuery("(min-width: 540px)");

  return (
    <div id="page-content-home">
      <ModalJoinForFree
        ref={ref}
        open={isModalOpen}
        formState={formState}
        setFormState={setFormState}
        toggleModal={toggleModal}
      />

      <div className="tabs">
        <div className="tab body-regular active">{t.forTheCompany}</div>
        <div className="tab body-regular">{t.forTheCandidate}</div>
      </div>
      <div className="hero">
        <p className="text-hero weight-300">{t.talentSearch}</p>
        <p className="text-hero weight-700">{t.onThePlatform}</p>
      </div>
      <div className="hero-buttons">
        <Link to={tryForFree("Company")}>
          <ContainedButtonWithIcon title={t.tryForFree} />
        </Link>
        <button className="button outlined-primary body-small weight-500">
          {t.learnMore}
        </button>
      </div>
      {isLargeScreen && (
        <div id="ticker" className="home-ticker-container">
          <HomeTicker />
        </div>
      )}

      {sections.map((section, index) => {
        const { intersectionRef, isIntersecting } = useIntersecting(
          section.threshold
        );

        useEffect(() => {
          if (isLargeScreen && isIntersecting && ["hero", "innovate", "bother"].includes(section.id)) {
            setCurrentSectionIndex(index);
          }
        }, [isIntersecting, isLargeScreen]);

        return (
          <div className="scroll-to-section">
            <div
              key={section.id}
              ref={(el) => {
                sectionRefs.current[index] = el;
                intersectionRef.current = el;
              }}
              className={`section section-${section.id}`}
            >
              {section.id === "hero" && (
                <>
                  <div className="section relative">
                    <div className="blur-ball blur-size-460 blur-bg-1 blur-filter-112-5 top-n89-left"></div>
                    <div className="blur-ball blur-size-400 blur-bg-2 blur-filter-90 top-n53-right"></div>
                  </div>
                  <div className="section section-platform before-gradient">
                    <LocalizedImage src="screen.jpg" className="hero-image" />
                  </div>
                  <div id="cat" className="section section-question">
                    <img src={geometry} alt="items icon" className="section-question-img" />
                    <p className="body-medium text-tertiary text-center">
                      {t.rethoricalQuestion}
                    </p>
                    <div className="question-dots">
                      {[1, 2, 3].map(i => (
                        <div key={i} className="question-dot"></div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {section.id === "innovate" && (
                <>
                  <h2
                    className="weight-300 text-secondary text-center section-innovate-head"
                    dangerouslySetInnerHTML={{ __html: t.innovatePlatform }}
                  />
                  <p className="text-secondary body-medium">{t.withUs}</p>
                  <div className="section-innovate-content">
                    <div className="section-innovate-list">
                      {innovateList.map((item, idx) => (
                        <div key={idx} className="card-innovate shadow-7">
                          <p className="text-secondary body-medium weight-600">
                            {item.title}
                          </p>
                          <p className="text-tertiary body-medium mt-8">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                    <img src={folder} alt="folder image" />
                  </div>
                  <div className="section-innovate-footer">
                    <div className="rounded-item">
                      <Sparkles className="icon-24 text-primary" />
                      <p>
                        <span className="text-tertiary body-medium">
                          {t.weAreHereToMakeYourWork}
                        </span>
                        <span className="text-primary body-medium">
                          {" "}
                          {t.easierAndMoreEfficient}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
              {section.id === "bother" && <Bother />}
              {section.id === "tasks" && <Tasks />}
              {section.id === "opportunities" && <Opportunities />}
              {section.id === "mobile-ticker" && !isLargeScreen && (
                <HomeTicker />
              )}
              {section.id === "prices-page" && (
                <Prices variant="home" toggleModal={toggleModal} />
              )}
              {section.id === "integrations" && (
                <div className="section-integrations-content">
                  <div className="opportunities-description">
                    <h2
                      className="text-secondary section-integrations-text weight-300"
                      dangerouslySetInnerHTML={{ __html: t.favoriteServices }}
                    />
                    <p className="mt-12 text-tertiary body-medium max-w-510">
                      {t.integrationDescription}
                    </p>
                  </div>
                  <IntegrationCards isIntersecting />
                </div>
              )}
              {section.id === "target" && <Targets />}
              {section.id === "reviews" && <Reviews />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
