import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Bolt } from "../../assets/bolt.svg";
import { ReactComponent as UserGroup } from "../../assets/user-group.svg";
import { ReactComponent as DocumentText } from "../../assets/document-text.svg";

import { paths } from "../../constants/paths";
import { useTranslation } from "../../hooks/useTranslation";

import { LocalizedImage } from "../LocalizedImage";
import { MarqueeAnimation } from "./MarqueeAnimation";
import { ModalCreateRequest } from "../ModalCreateRequest";
import { ContainedButtonWithIcon } from "../buttons/ContainedButtonWithIcon";

const availablePaths = ["atsDatabase", "hrPlatform", "vacancies"];

export const ProductHead = ({
  activePath,
  title,
  subtitle,
  image,
  tags,
  animation = false,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const products = useMemo(
    () => [
      { title: t.vacancies, icon: <DocumentText />, to: paths.vacancies },
      { title: t.atsDatabase, icon: <UserGroup />, to: paths.atsDatabase },
      { title: t.hrPlatform, icon: <Bolt />, to: paths.hrPlatform },
    ],
    [t]
  );

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevState) => {
      document.body.style.overflow = !prevState ? "hidden" : "";

      return !prevState;
    });
  }, []);

  return (
    <div className={`product-vacancies ${animation ? "mt-60" : "mt-40"}`}>
      <div className="side-left">
        {activePath && availablePaths.includes(activePath) && (
          <div className="product-button-list">
            {products.map((product) => {
              const active = product.to === paths[activePath] ? "active" : "";

              return (
                <Link key={product.to} to={product.to}>
                  <button className={`product-button ${active}`}>
                    <div
                      className={`product-button-icon text-primary ${active}`}
                    >
                      {product.icon}
                    </div>
                    <p className="body-small">{product.title}</p>
                  </button>
                </Link>
              );
            })}
          </div>
        )}
        <p
          className={`${
            animation ? "a-80" : "a-32"
          } text-hero weight-300 letter-spacing-right-to-left`}
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>

        <p className="mt-24 body-medium text-secondary">{subtitle}</p>

        {tags && (
          <div className="tags">
            {tags.map((tag, index) => (
              <div
                key={`${tag}-${index}`}
                className="chip body-small text-tertiary"
              >
                # {tag}
              </div>
            ))}
          </div>
        )}

        <ModalCreateRequest open={isModalOpen} toggleModal={toggleModal} />

        <ContainedButtonWithIcon
          className="request-button"
          title={t.tryForFree}
          onClick={toggleModal}
        />
      </div>
      <div
        className={`side-right ${animation ? "" : "overflow-hidden"} ${
          image?.relative || ""
        }`}
      >
        {!!image?.src && (
          <>
            {image.src === "product-2.png" && (
              <div className="relative">
                <LocalizedImage
                  src="product-2-card.jpg"
                  className="product-image-card"
                />
              </div>
            )}
            <div className="before-gradient after-gradient-right-to-left">
              <div className="before-gradient-left-to-right">
                <LocalizedImage
                  src={image.src}
                  className={`product-image ${image.class}`}
                />
              </div>
            </div>
            {image?.blurClass && (
              <div className={`blur-ball ${image.blurClass}`}></div>
            )}
            {image?.blur2Class && (
              <div className={`blur-ball ${image.blur2Class}`}></div>
            )}
          </>
        )}
        {animation && (
          <div className="relative">
            <div className="blur-ball blur-size-312 blur-bg-6 blur-filter-50 left-center"></div>
            <MarqueeAnimation />
          </div>
        )}
      </div>
    </div>
  );
};
